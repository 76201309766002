async function nav() {
	let navBarToggler = document.querySelector('.nav__toggler'),
		body = document.body,
		navBottom = document.querySelector('.nav__bottom');
	navBottom.style.maxHeight = navBottom.querySelector('.nav__nav').offsetHeight + 'px'
	navBarToggler.addEventListener("click", function () {
		navBarToggler.classList.toggle('nav__toggler--active');
		body.classList.toggle('nav-is-open');
		navBottom.classList.toggle('nav__bottom--active');
		if (window.innerWidth < 991) {
			navBottom.style.maxHeight = navBottom.querySelector('.nav__nav').offsetHeight + 'px'
		}
	});
	if (window.innerWidth > 991) {
		setInterval(() => {
			document.querySelectorAll('.menu__item--dropdown__wrapper').forEach((dropdown, i) => {
				let innerHeight = dropdown.querySelector('ul').offsetHeight;
				dropdown.style.maxHeight = innerHeight + 'px';
			});
		}, 500);
	}
	let menuHeight = document.querySelector('.menu').offsetHeight
	let windowWidth = window.innerWidth;
	window.addEventListener('resize', () => {
		windowWidth = window.innerWidth;
		if (windowWidth > 991) {
			document.querySelector('.menu').style.height = 'unset'
		}
	})
	document.querySelectorAll('.menu__item').forEach(item => {
		if (item.querySelector('.menu__item--dropdown__wrapper')) {
			let itemHeight = item.offsetTop + item.querySelector('ul').offsetHeight;
			item.querySelector('.menu__item__link').addEventListener('click', (e) => {
				if (windowWidth < 991) {
					e.preventDefault();
					item.classList.add('selected');
					document.querySelector('.menu').classList.add('menu--active')
					console.log(itemHeight);

					document.querySelector('.menu').style.height = (itemHeight - 120) + 'px';
					setTimeout(() => {
						navBottom.style.maxHeight = (navBottom.querySelector('.nav__nav').offsetHeight) + 'px'
					}, 200)
				}
			})
			item.querySelector('.menu__item--dropdown__wrapper button').addEventListener('click', (e) => {
				e.stopPropagation();
				e.preventDefault();
				if (windowWidth < 991) {
					item.classList.remove('selected');
					document.querySelector('.menu').classList.remove('menu--active')
					document.querySelector('.menu').style.height = menuHeight + 'px';
					setTimeout(() => {
						navBottom.style.maxHeight = navBottom.querySelector('.nav__nav').offsetHeight + 'px'
					}, 200)
				}
			})
		}
	})

	// 	var nav = document.querySelector('.nav');
	// if (nav) {
	// 	function showNav(thisWindow) {
	// 		var scroll = thisWindow.scrollY;
	// 		if (scroll > (100)) {
	// 			nav.classList.add('nav--fixed');
	// 		} else {
	// 			nav.classList.remove('nav--fixed');
	// 		}
	// 	}
	// 	showNav(window);
	// 	window.addEventListener("scroll", function(event) {
	// 		showNav(this);
	// 	});
	// }
	let first = true;
	document.querySelectorAll('.link_dropdown').forEach(item => {
		item.querySelector('.link_dropdown__links').style.maxHeight = (item.querySelector('.link_dropdown__links ul').offsetHeight + 10) + 'px';
		if (first) {

			item.querySelector('.link_dropdown__title').classList.add('link_dropdown__title--active');
			item.querySelector('.link_dropdown__links').classList.add('link_dropdown__links--active');
			first = false;
		}
		item.querySelector('.link_dropdown__title').addEventListener('click', () => {
			item.querySelector('.link_dropdown__title').classList.toggle('link_dropdown__title--active');
			item.querySelector('.link_dropdown__links').classList.toggle('link_dropdown__links--active');
		})
	})
	document.querySelectorAll('.filter--mobile').forEach(filt => {
		let active = false;
		filt.querySelector('.btn').addEventListener('click', event => {
			active = !active
			filt.querySelector('.filter--mobile__inner').classList.toggle('active')
			filt.querySelector('.filter--mobile__inner').style.maxHeight = active ? filt.querySelector('.filter--mobile__inner__inner').offsetHeight + 'px' : 0
		})
	})

	let popup = document.querySelector('.popup');
	let close = popup.querySelector('.popup__close')
	let rush = document.querySelector('span.btn--rush');
	let customButtons = document.querySelectorAll(`[href*='#popup']`);
	if (customButtons) {
		console.log("custom are present;")
		customButtons?.forEach(link => {
			link.addEventListener('click', openPopup)
		});
	}

	if (rush) {
		rush.addEventListener('click', openPopup);
	}
	if (rush || customButtons) {
		close.addEventListener('click', closePopup);
		popup.addEventListener('click', e => {
			if (e.target == popup) {
				popup.classList.remove('popup--active');
			}
		})
	}

	// Handles for click events;
	function openPopup(evt) {
		// console.log('Open evt', evt);
		popup.classList.add('popup--active');
	}
	function closePopup(evt) {
		// console.log('Close evt', evt);
		popup.classList.remove('popup--active');
	}

}
export {
	nav
}