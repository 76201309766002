// Import Swiper and modules
import '../../config/node_modules/regenerator-runtime/runtime';

/* Libraries */
// import {
// 	gsap
// } from "../../config/node_modules/gsap";

async function loadSwiper() {
	if (document.querySelector('.swiper-wrapper')) {
		let swiper = await import('./scripts/modules/swiper');
		swiper.swiper();
	}
}
loadSwiper();
import { searchBar } from './scripts/search_bar'
searchBar()


/* Modules */
async function loadFilter() {
	if (document.querySelector('.filter__input')) {
		let filter = await import('./scripts/modules/filter/filter');
		filter.filter();
	}
}
loadFilter();

async function loadAccordion() {
	if (document.querySelector('.accordion')) {
		let accordion = await import('./scripts/modules/accordion');
		accordion.accordion();
	}
}
loadAccordion();
async function loadFormSteps() {
	if (document.querySelector('.form_steps')) {
		let formSteps = await import('./scripts/modules/form/form_steps');
		formSteps.initFormSteps();
	}
}
loadFormSteps();

async function loadForm() {
	if (document.querySelector('.contact_form')) {
		let form = await import('./scripts/modules/form/form');
		form.form();
	}
}
loadForm();

async function loadSelectors() {
	if (document.querySelector('.selector')) {
		let imp = await import('./scripts/modules/selector');
		imp.allSelectors();
	}
}
loadSelectors();

import {
	nav
} from './scripts/modules/nav'
nav();

async function loadVideo() {
	if (document.querySelector('.video')) {
		let form = await import('./scripts/modules/video');
		form.video();
	}
}
loadVideo();

async function loadLightbox() {
	if (document.querySelector('.lightbox')) {
		let lightbox = await import('./scripts/modules/lightbox');
		lightbox.lightbox();
	}
}
loadLightbox();

async function loadMap() {
	if (document.querySelector('.map')) {
		let map = await import('./scripts/modules/map');
		map.map(document.querySelector('.news_section__inner'));
	}
}
loadMap()

/* Functions */
import {
	lazyload
} from './scripts/functions/lazyload'
lazyload(document)
import {
	scrollTo
} from './scripts/functions/scrollTo';
scrollTo()

/* Styles */
import './styles/style';