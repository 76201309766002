export function searchBar() {
   document.querySelectorAll('.search_bar').forEach(bar => {
      if (bar.querySelector('input').value) {
         bar.querySelector('.search_bar__text').style.display = 'none'
      } else {
         bar.querySelector('.search_bar__text').style.display = 'block'
      }
      bar.querySelector('input').addEventListener("keyup", event => {
         if (event.target.value) {
            bar.querySelector('.search_bar__text').style.display = 'none'
         } else {
            bar.querySelector('.search_bar__text').style.display = 'block'
         }
      })
   })
}