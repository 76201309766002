async function scrollTo() {
	window.addEventListener('DOMContentLoaded', () => {
		const links = document.getElementsByTagName('a'),
			supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

		Array.from(links).forEach((link) => {
			link.addEventListener('click', (e) => {
				let targetID = link.getAttribute('href');
				if (targetID.includes('#') && targetID != '#') {
					e.preventDefault();
					let targetAnchor = document.querySelector(targetID);
					if (targetAnchor) {
						let scrollTop = targetAnchor.getBoundingClientRect().top + window.scrollY;

						scrollToElem(scrollTop);
					}
				};
			})
		});

		const nativeSmoothScrollTo = positionTop => {
			window.scroll({
				behavior: 'smooth',
				left: 0,
				top: positionTop
			});
		};

		const smoothScrollTo = (to, duration) => {
			const element = document.scrollingElement || document.documentElement,
				start = element.scrollTop,
				change = to - start,
				startDate = +new Date();

			const easeInOutQuad = (t, b, c, d) => {
				t /= d / 2;
				if (t < 1) return c / 2 * t * t + b;
				t--;
				return -c / 2 * (t * (t - 2) - 1) + b;
			};

			const animateScroll = _ => {
				const currentDate = +new Date();
				const currentTime = currentDate - startDate;
				element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
				if (currentTime < duration) {
					requestAnimationFrame(animateScroll);
				} else {
					element.scrollTop = to;
				}
			};
			animateScroll();
		};

		const scrollToElem = elemSelector => {
			if (!elemSelector && elemSelector != 0) {
				return;
			}

			let positionTop = elemSelector - 100;

			if (supportsNativeSmoothScroll) {
				nativeSmoothScrollTo(positionTop);
			} else {
				smoothScrollTo(positionTop, 600);
			}
		};
	});
}

export {
	scrollTo
}